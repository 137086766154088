import React from 'react';

const Alert = ({ children, variant = 'default' }) => {
  const baseClasses = "p-4 rounded-lg border";
  const variantClasses = {
    default: "bg-gray-100 border-gray-200 text-gray-800",
    destructive: "bg-red-100 border-red-200 text-red-800",
  };

  return (
    <div className={`${baseClasses} ${variantClasses[variant]}`}>
      {children}
    </div>
  );
};

const AlertTitle = ({ children }) => (
  <h5 className="mb-1 font-medium">{children}</h5>
);

const AlertDescription = ({ children }) => (
  <div className="text-sm">{children}</div>
);

export { Alert, AlertTitle, AlertDescription };