import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, AlertDescription } from './components/ui/Alert';
import { Button } from './components/ui/Button';
import { Input } from './components/ui/Input';
import { Card, CardHeader, CardContent } from './components/ui/Card';
import { AlertTriangle, Globe } from 'lucide-react';

const CloudflareConfigDashboard = () => {
  const [apiToken, setApiToken] = useState('');
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDomains = async () => {
    setLoading(true);
    setError(null);
    setDomains([]);
    try {
      const response = await fetch('/api/zones', {
        method: 'GET',
        headers: {
          'CF-API-Token': apiToken,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch domains');
      }
      if (!data.result || !Array.isArray(data.result)) {
        throw new Error('Invalid response format from server');
      }
      setDomains(data.result.map(zone => ({
        id: zone.id,
        name: zone.name,
        status: zone.status,
      })));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Cloudflare Configuration Dashboard</h1>
      <div className="mb-6 flex">
        <Input
          type="password"
          placeholder="Enter Cloudflare API Token"
          value={apiToken}
          onChange={(e) => setApiToken(e.target.value)}
          className="flex-grow mr-2"
        />
        <Button onClick={fetchDomains} disabled={loading || !apiToken}>
          {loading ? 'Loading...' : 'Refresh Domains'}
        </Button>
      </div>
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {domains.map((domain) => (
          <Card key={domain.id} className="mb-4">
            <CardHeader>
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">{domain.name}</h3>
                <Globe className={domain.status === 'active' ? 'text-green-500' : 'text-red-500'} />
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-600 mb-4">
                Status: <span className={`font-semibold ${domain.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>
                  {domain.status.charAt(0).toUpperCase() + domain.status.slice(1)}
                </span>
              </p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default CloudflareConfigDashboard;